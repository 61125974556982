import React from "react";

export default function AboutUs() {
  return (
    <div className="_w-100 text-bg-dark text-white pt-5 pb-5 mt-3 information">
      <div className="m-auto pt-3">
        <h2>About Us – UK Best Casino</h2>
        <span>18+</span>
        <p>
          At <strong>UK Best Casino</strong>, we provide you with comprehensive
          and up-to-date information about online casinos in the UK. Our expert
          reviews and guides help you find the safest, most exciting gambling
          platforms. Whether you're an experienced player or a beginner, we are
          here to ensure you have the best possible experience.
        </p>
        <p>
          <p>
            We promote responsible gaming and ensure all casinos we recommend
            adhere to the highest standards of fairness and safety.
          </p>
        </p>
        <h4>Contact Us</h4>
        <p>
          Did you find our site helpful? Do you have suggestions for
          improvement? We would love to hear from you.
        </p>
        {/* <p>
          For general inquiries, comments, or suggestions, feel free to email us
          at xxx@xxx.com.
        </p> */}
      </div>
    </div>
  );
}
